<template>
  <div id="fbw" style="margin-bottom:100px;">
    <img src="../../assets/fbw/banner1.png" style="width:100%;margin-bottom:20px;">
    <div class="box">
      <div class="box-title">AI评估更靠谱，让商标注册成功率提升80%</div>
      <Field label-width="100px" v-model.trim="brandName" label="商 标 名 称" placeholder="请输入要评估的商标名称" @input="checkWords"
             @change="check('brandName')"/>
      <Field style="width:100%;" label-width="100px" v-model.trim="serviceName" label="商品或服务名称"
             placeholder="请输入商品或服务，比如面包" readonly @click="goSearch"/>
      <div class="button" @click="go">点击免费试用</div>
      <div class="tips">
        已有 <span style="color:#218CF5">1829</span>家企业成功注册商标
      </div>
    </div>
    <img src="../../assets/fbw/banner2.png" style="width:100%;margin:20px 0;">
    <div class="box">
      <div class="box-title">保护企业品牌，侵权风险早知道</div>
      <Field label-width="100px" v-model="companyName" label="企 业 名 称" placeholder="请输入要查询的企业名称" readonly
             @click="$router.push('/searchCompany')"/>
      <div class="button" @click="$router.push('/searchCompany')">点击免费试用</div>
    </div>
<!--    <div class="tips2">-->
<!--      以上服务由知依昇为您提供-->
<!--    </div>-->
<!--    <div style="color:#fff;width:80%;margin:0 auto;">-->
<!--      <h4 style="text-align: center;margin-bottom:5px;padding-bottom:0;">知依昇</h4>-->
<!--      <p style="font-size:12px;">-->
<!--        知昇（杭州）人工智能科技有限公司，以大数据、AI算法驱动，致力于研发以提升商标注册成功率为市场入口的AI知识产权SaaS服务系统，将对企业的知产服务标准化、线上化、品质化、透明化，降低企业知识产权保护的时间成本和确权维权成本。-->
<!--      </p>-->
<!--    </div>-->
    <consult></consult>
  </div>
</template>

<script>
import {Field, Toast} from 'vant';
import {forbid, insertClickLog, signLogin} from "@/api/api";
import store from "@/store";
import Consult from "@/components/consult";
import {debounce} from "lodash";

export default {
  name: "fbwHome",
  data() {
    return {
      companyName: '',
      brandName: '',
      serviceName: ''
    }
  },
  methods: {
    async go() {
      if (this.brandName && this.serviceName) {
        if(!this.check('brandName')) return false
        if(this.brandName){
          let result = await this.forbid()
          if(!result) return false
        }
        this.$router.push(`/fbw/recommend?brandName=${this.brandName}&serviceName=${this.serviceName}`)
      }else if(!this.brandName){
        Toast('商标名称不能为空')
        return false
      }else if(!this.serviceName){
        Toast('商品或服务名称不能为空')
        return false
      }
    },
    goSearch() {
      this.$router.push(`/fbw/search?value=${this.serviceName}`)
    },
    async signLogin() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...'
      });
      let {userMobile, accessKey, userNickname, sign} = this.$route.query;
      if (!sign) {
        setTimeout(()=>{
          Toast.clear();
        },500)
        return false;
      }
      let res = await signLogin({
        userMobile, accessKey, userNickname, sign: decodeURIComponent(sign)
      })
      if (res.code === 200) {
        this.$store.commit('setUserInfo', res.data)
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        console.log(this.$store.state.userInfo);
        //记录日志
        await this.insertClickLog(res.data)
        setTimeout(()=>{
          Toast.clear();
        },500)
      }else{

      }
      console.log(res);
    },
    async insertClickLog({userCode, userName}) {
      let res = await insertClickLog({
        userCode,
        userName,
        channels: 'FBW',
        urlName: 'FBW静默登录'
      })
    },
    check(key) {
      const regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
          regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im,
          regNum = /\D/g
      if(!regNum.test(this[key])){
        Toast("不能为纯数字");
        return false;
      }
      if (regEn.test(this[key]) || regCn.test(this[key])) {
        Toast("不能包含特殊字符");
        return false;
      }
      return true
    },
    async forbid() {
      if(!this.brandName) return false
      let res = await forbid({
        text: this.brandName
      })
      if (res.code === 200 && res.data.forbid === 'True') {
        Toast(res.data.reason)
        return false
      }
      return true
    },
    checkWords: debounce(function () {
      this.forbid()
    }, 500)
  },
  mounted() {
    this.$store.commit('setOrigin','FBW')
    if (!this.$store.state.userInfo || !this.$store.state.userInfo.userToken) this.signLogin();
  },
  activated() {
    if (this.$route.query.search || this.$route.query.search === '') {
      this.serviceName = this.$route.query.search
    }
  },
  components: {
    Consult,
    Field
  }
}
</script>

<style scoped>
.tips2 {
  color: #fff;
  text-align: center;
  font-size: 12px;
  background: #254EC1;
  opacity: 0.82;
  border-radius: 4px;
  height: 25px;
  line-height: 25px;
  margin-top: 10px;
}

.van-cell {
  background-color: transparent;
}

#fbw {
  background-image: url(../../assets/fbw/bg.png);
  background-size: 100% auto;
  padding: 220px 10px 30px 10px;
  background-repeat: no-repeat;
  background-color: #4590ED;
}

.banner1 {
  width: 100%;
}

.box {
  background: #F1F8FD;
  border-radius: 4px;
  padding: 25px 20px;
}

.box-title {
  background: #E8F3FC;
  border-radius: 4px;
  font-weight: bold;
  color: #218CF5;
  font-size: 14px;
  text-align: center;
  padding: 3px 0;
  margin-bottom: 15px;
}

.button {
  background: linear-gradient(180deg, #31B2FC, #1C81F3);
  border-radius: 4px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
}

.tips {
  font-size: 14px;
  text-align: center;
  padding-top: 20px;
}

.van-cell {
  padding-right: 0;
  padding-left: 0;
}
</style>